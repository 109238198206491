import React from 'react';
import { useSession } from 'utils/sessionProvider';
import AuthButton from '../components/AuthButton';
import SignInTwoFactor from '../SignInTwoFactor';
import { AuthLogicProvider, useAuthLogic } from './utils/SignInLogic';
import { SigningInWithPasswordScreen } from './screens/SignInWithPasswordLogic';
import NoTenant from '../../../apps/TenantApp/screens/NoTenant';
import AlreadySignedIn from '../AlreadySignedIn';
import SignInScreen from './screens/SignIn';
import { SignInViaMailPinScreen } from './screens/SignInViaMailPinScreen';
import SigninPickPlatformScreen from './screens/PickTenant';
import { SelectPlatformScreen } from './screens/SelectPlatform';
import { allowNonTenantAuthentication } from 'apps/TenantApp/Routes';

export const GoogleButton = () => {
  const auth = useAuthLogic();
  return (
    <>
      {auth.authOptions?.map(({ url, id }) => (
        <AuthButton
          w="full"
          key={id}
          id={id}
          token={auth.authToken}
          href={url!}
        />
      ))}
    </>
  );
};

const ScreenSwitch = () => {
  const { needsTwoFactor, isAuthenticated } = useSession();
  const { screen } = useAuthLogic();
  const { tenant } = useSession();

  if (!tenant && !allowNonTenantAuthentication()) {
    return <NoTenant />;
  }

  if (isAuthenticated) {
    if (needsTwoFactor) {
      return <SignInTwoFactor />;
    } else {
      return <AlreadySignedIn />;
    }
  }

  switch (screen.screen) {
    case 'select-platform':
      return <SelectPlatformScreen />;
    case 'forgot-platform':
      return <SignInScreen mode="find-by-email" />;
    case 'login-with-password-for-app':
      return <SigningInWithPasswordScreen tenant={screen.tenant} />;
    case 'login-with-password':
      return <SigningInWithPasswordScreen tenant={tenant!} />;
    case 'with-email-pin':
      return (
        <SignInViaMailPinScreen login={screen.login} token={screen.token} />
      );
    case 'pick-tenant':
      return <SigninPickPlatformScreen accounts={screen.accounts} />;
    default:
      return <SignInScreen />;
  }
};

const SignIn = () => {
  return (
    <AuthLogicProvider>
      <ScreenSwitch />
    </AuthLogicProvider>
  );
};

export default SignIn;
