import { lazy } from '@loadable/component';
import { DocumentTitle } from 'components/DocumentTitle';
import NotificationHandler from 'components/NotificationHandler';
import React from 'react';
import { AppCrashed } from 'screens/AppCrashed';
import { LayoutProvider } from 'utils/LayoutProvider';
import ThumbnailRefreshProvider from 'utils/ThumbnailRefreshProvider';
import { RelaySafeProvider } from 'utils/graphClient';
import { I18nProvider } from 'utils/i18n';
import { SessionProvider, useSession } from 'utils/sessionProvider';
import { UploaderProvider } from '../../providers/UploaderProvider';
import TenantAppRoutes from './Routes';
import DefaultLanguageHandler from './components/DefaultLanguageHandler';
import { LinkClickObserver } from './components/LinkClickObserver';
import CUSTOM_THEME from './theme';
import { CustomThemeProvider } from './theme/CustomThemeProvider/provider';

const SignInTwoFactor = lazy(
  () => import('screens/Authentication/SignInTwoFactor'),
);

const TenantApp = () => {
  return (
    <SessionProvider key="sessionProvider">
      <CustomThemeProvider baseTheme={CUSTOM_THEME}>
        <LayoutProvider>
          <I18nProvider>
            <ThumbnailRefreshProvider>
              <NotificationHandler />
              <LinkClickObserver />
              <DefaultLanguageHandler />
              <RelaySafeProvider ErrorComponent={AppCrashed}>
                <UploaderProvider>
                  <InnerTenantApp />
                </UploaderProvider>
              </RelaySafeProvider>
            </ThumbnailRefreshProvider>
          </I18nProvider>
          <TenantDocumentTitle />
        </LayoutProvider>
      </CustomThemeProvider>
    </SessionProvider>
  );
};

const TenantDocumentTitle = () => {
  const { tenant } = useSession();
  return (
    <DocumentTitle
      title={
        tenant?.name ? `${tenant.name} - Trusted Family` : `Trusted Family`
      }
    />
  );
};

export default TenantApp;

const InnerTenantApp = () => {
  const { needsTwoFactor } = useSession();
  if (needsTwoFactor) {
    return <SignInTwoFactor />;
  } else {
    return <TenantAppRoutes />;
  }
};
