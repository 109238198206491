import React from 'react';
import { t, Trans } from '@lingui/macro';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  FormControl,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from './components/AuthenticationScreen';
import * as EmailValidator from 'email-validator';
import { TenantData } from './SignIn/utils/loadTenantData';
import { FORGOT_PASSWORD_PATH } from 'utils/routes';
import isAppDomain from 'utils/isAppDomain';
import { PasswordInput } from 'components/PasswordInput';
import { useForm } from 'react-hook-form';

export type TenantPasswordLoginProps = {
  onSubmitLogin: (email: string, password: string) => Promise<void>;
  onLookingForOtherPlatform: () => void;
  ExternalAuth: React.ElementType;
  mobileTenantName?: string;
  tenant: TenantData;
};

export const TenantPasswordLogin = ({
  onSubmitLogin,
  onLookingForOtherPlatform,
  ExternalAuth,
  tenant,
}: TenantPasswordLoginProps) => {
  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const isMobileApp = isAppDomain();

  const onSubmit = handleSubmit(({ email, password }) => {
    return onSubmitLogin(email, password);
  });

  return (
    <AuthenticationScreen>
      <Stack spacing={4}>
        <VStack>
          <Image
            src={tenant?.icon?.url}
            borderWidth="2px"
            borderRadius="md"
            borderColor="white"
            borderStyle="solid"
            alt=""
            boxSize="50px"
            objectFit="cover"
            textAlign="center"
          />
          <Heading fontWeight="bold" fontSize="2xl" mb={2} textAlign="center">
            <Trans>Sign in to {tenant?.name ?? 'Trusted Family'}</Trans>
          </Heading>
        </VStack>

        <VStack as="form" onSubmit={onSubmit}>
          <FormControl>
            <Input
              autoFocus
              {...register('email', {
                required: true,
                validate: { email: EmailValidator.validate },
              })}
              type="email"
              placeholder={t`name@your-email.com`}
            />
          </FormControl>
          <FormControl>
            <PasswordInput
              {...register('password', { required: true })}
              placeholder={t`Your password`}
              autoComplete="current-password"
            />
          </FormControl>

          <Button
            variant="solid"
            colorScheme="blue"
            type="submit"
            w="full"
            mt={2}
            isDisabled={!formState.isValid}
            isLoading={formState.isSubmitting}
          >{t`Sign in`}</Button>
        </VStack>

        <VStack alignItems="flex-start" fontSize="xs" color="gray.500">
          <HStack gap={1} wrap="wrap">
            <Text>{t`Forgot your password?`}</Text>
            <Link
              to={
                isAppDomain() && tenant?.domain
                  ? `${FORGOT_PASSWORD_PATH}?${new URLSearchParams({
                      tenant: tenant.domain,
                    })}`
                  : FORGOT_PASSWORD_PATH
              }
              fontWeight="bold"
              textDecoration="underline"
            >{t`Reset password here`}</Link>
          </HStack>

          {isMobileApp && (
            <HStack gap={1} wrap="wrap">
              <Text>{t`Looking for another platform?`}</Text>
              <Link
                to="#"
                onClick={preventDefault(onLookingForOtherPlatform)}
                fontWeight="bold"
                textDecoration="underline"
              >{t`Find your platform`}</Link>
            </HStack>
          )}
          <Box position="relative" paddingY={4} w="full">
            <Divider />
            <AbsoluteCenter
              px={4}
              bg={useColorModeValue('white', 'gray.700')}
              color="gray.500"
            >{t`OR`}</AbsoluteCenter>
          </Box>
          <ExternalAuth />
        </VStack>
      </Stack>
    </AuthenticationScreen>
  );
};

const preventDefault =
  (callback: (event: React.MouseEvent) => unknown) =>
  (event: React.MouseEvent) => {
    event.preventDefault();
    callback(event);
  };
