import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  useLazyLoadQuery,
  UseMutationConfig,
  useMutation as useMutationRelay,
} from 'react-relay';
import {
  Disposable,
  GraphQLSubscriptionConfig,
  GraphQLTaggedNode,
  MutationParameters,
  OperationType,
  PayloadError,
} from 'relay-runtime';

type ErrorBehaviourType =
  | typeof CrashErrorBehaviour
  | typeof ToastErrorBehaviour
  | typeof HandledErrorBehaviour;

export const HandledErrorBehaviour = 'HANDLED';
export const CrashErrorBehaviour = 'CRASH';
export const ToastErrorBehaviour = 'TOAST';

export interface ErrorHandlingMutationConfig<T extends MutationParameters>
  extends UseMutationConfig<T> {
  payloadErrorBehaviour?: ErrorBehaviourType;
}

export function useMutation<T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
): [(config: ErrorHandlingMutationConfig<T>) => Disposable, boolean] {
  const toast = useToast();
  const [activeErrorHandler, setActiveErrorHandler] = useState<
    (() => void | never) | undefined
  >(undefined);
  const [commitMutation, areMutationsInFlight] = useMutationRelay<T>(mutation);

  useEffect(() => {
    if (activeErrorHandler) {
      activeErrorHandler();
    }
  }, [activeErrorHandler]);

  return [
    (config: ErrorHandlingMutationConfig<T>) => {
      const { payloadErrorBehaviour, ...userConfig } = config;
      userConfig.onError =
        userConfig?.onError ||
        ((e) => {
          setActiveErrorHandler(() => {
            defaultOnError([e], toast);
          });
        });
      userConfig.onCompleted = (response, errors) => {
        if (errors?.length) {
          setActiveErrorHandler(() => {
            defaultOnError(errors, toast, payloadErrorBehaviour);
          });
        }
        config.onCompleted && config.onCompleted(response, errors);
      };
      return commitMutation(userConfig);
    },
    areMutationsInFlight,
  ];
}

const SERVER_ERROR_TOAST_ID = 'server_error';

function defaultOnError(
  errors: Error[] | PayloadError[],
  toast: ReturnType<typeof useToast>,
  behaviour?: ErrorBehaviourType,
) {
  if (behaviour === 'HANDLED') {
    return;
  }
  if (behaviour === 'TOAST') {
    if (!toast.isActive(SERVER_ERROR_TOAST_ID)) {
      toast({
        id: SERVER_ERROR_TOAST_ID,
        title: t`Server Error`,
        status: 'error',
        isClosable: true,
      });
    }
  } else {
    throw errors[0];
  }
}

export const useLazyLoadQueryWithRef = <T extends OperationType>(
  query: GraphQLTaggedNode,
  variables: GraphQLSubscriptionConfig<T>['variables'],
  options?: Parameters<typeof useLazyLoadQuery>[2],
) => {
  const data = useLazyLoadQuery<T>(query, variables, options);

  return [data, { query, variables, options }] as const;
};
