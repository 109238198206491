import {
  Avatar,
  Collapse,
  Heading,
  HStack,
  Text,
  VStack,
} from '@cardboard-ui/react';
import React, { FC, useCallback } from 'react';
import { t } from '@lingui/macro';
import { AuthenticationScreen } from './components/AuthenticationScreen';
import { TrustedFamilyLogo } from './assets/Logo';
import { CenteredSpinner } from 'components/CenteredSpinner';

export type Tenant = {
  name: string;
  domain: string;
  icon_url: null | string;
  shortcode: string;
};

export type Account = {
  auth_url: string;
  tenant: Tenant;
};

type SigninPickPlatform = {
  onSwitch: (tenant: Account) => Promise<void>;
  accounts: Account[];
};
export const SigninPickPlatform = ({
  onSwitch,
  accounts,
}: SigninPickPlatform) => {
  const [switchingInFlight, setSwitchingInFlight] = React.useState(false);

  const handleSwitch = useCallback(
    (account: Account) => {
      if (switchingInFlight) {
        return;
      }

      setSwitchingInFlight(true);
      onSwitch(account).catch(() => {
        setSwitchingInFlight(false);
      });
    },
    [onSwitch, switchingInFlight],
  );

  return (
    <AuthenticationScreen>
      <VStack>
        <TrustedFamilyLogo />
        <Heading fontWeight="bold" fontSize="2xl">{t`Welcome back!`}</Heading>
        <Text color="gray.500" fontSize="sm">{t`Choose a platform below`}</Text>
      </VStack>

      {switchingInFlight && <CenteredSpinner />}
      <Collapse in={!switchingInFlight}>
        <VStack justifyContent="flex-start" gap={0}>
          {accounts.map((tenant) => (
            <AuthCard
              key={tenant.tenant.domain}
              tenant={tenant}
              onClick={() => onSwitch(tenant)}
            />
          ))}
        </VStack>
      </Collapse>
    </AuthenticationScreen>
  );
};

interface AuthCardProps {
  tenant: Account;
  onClick: () => void;
}

const AuthCard: FC<AuthCardProps> = ({ tenant, onClick }) => {
  return (
    <HStack
      as="button"
      onClick={onClick}
      form={tenant.tenant.domain}
      my={1}
      p={2}
      _hover={{ bg: 'gray.100', _dark: { bg: 'gray.800' } }}
      w="100%"
      rounded="md"
      transition=".1s all"
    >
      <Avatar
        name={tenant.tenant.name}
        src={tenant.tenant.icon_url || undefined}
        bg={tenant.tenant.icon_url ? 'transparent' : undefined}
        borderWidth="2px"
        borderRadius="md"
        borderColor="white"
        borderStyle="solid"
        boxSize="50px"
        objectFit="cover"
        textAlign="center"
      />
      <VStack align="start" spacing={0}>
        <Heading as="span" size="md">
          {tenant.tenant.name}
        </Heading>
        <Text fontSize="xs">{tenant.tenant.domain}</Text>
      </VStack>
    </HStack>
  );
};
