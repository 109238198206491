import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  useToast,
  VStack,
} from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { useFlag } from '@unleash/proxy-client-react';
import { ExperimentalIcon } from 'components/icons';
import { useLocalStorage } from 'hooks/useBrowserStorage';
import React from 'react';
import { useEffect } from 'react';
import { DEFAULT_LOCALE, useI18n } from 'utils/i18n';

export const DefaultLanguageHandler = () => {
  const toast = useToast();
  const useDeviceLanguage = useFlag('use_device_language');
  const [dismissedDefaultLanguageHandler, setDismissedDefaultLanguageHandler] =
    useLocalStorage('dismissedDefaultLanguageHandler', false, 'device');
  const { setLocale, locale, accountLocale } = useI18n();
  const deviceOverrideAccountLocale = accountLocale && accountLocale !== locale;

  useEffect(() => {
    if (window.location.hostname.includes('localtest')) return;
    if (!useDeviceLanguage) return;
    if (dismissedDefaultLanguageHandler) return;
    if (!deviceOverrideAccountLocale) return;

    if (!toast.isActive('locale_handler')) {
      toast({
        id: 'locale_handler',
        duration: 30 * 1000,
        onCloseComplete: () => {
          setDismissedDefaultLanguageHandler(true);
        },
        render: ({ onClose }) => {
          return (
            <Alert colorScheme="purple">
              <AlertIcon alignSelf="start">
                <ExperimentalIcon />
              </AlertIcon>
              <AlertDescription>
                <VStack align="start">
                  <Box>
                    {t`We changed the app to your device's language. You can opt-out of our new translations if you want.`}
                  </Box>
                  <ButtonGroup p={0}>
                    <Button
                      onClick={onClose}
                      colorScheme="purple"
                    >{t`Use my language`}</Button>
                    <Button
                      onClick={() => {
                        setLocale(accountLocale || DEFAULT_LOCALE);
                        onClose();
                      }}
                      variant="ghost"
                    >{t`Opt out, use default language`}</Button>
                  </ButtonGroup>
                </VStack>
              </AlertDescription>
            </Alert>
          );
        },
      });
    }
  }, [deviceOverrideAccountLocale, dismissedDefaultLanguageHandler]);

  return null;
};

export default DefaultLanguageHandler;
