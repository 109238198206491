import { Capacitor, CapacitorHttp, HttpResponse } from '@capacitor/core';
import * as Sentry from '@sentry/react';
import { object, string } from 'checkeasy';

export const getCsrfToken = async ({ refresh } = { refresh: false }) =>
  Capacitor.isNativePlatform()
    ? getNativeCsrfToken(refresh)
    : getDocumentCsrfToken(refresh);

// It is possible that the csrf-token in the dom gets outdated as well
// A long open page may become stale. Instead of forcing a refresh,
//   or crashing the app, we can just update the CSRF token in the DOM.
const getDocumentCsrfToken = async (refresh: boolean) => {
  if (refresh) {
    const newToken = await fetchCsrfTokenFromHost();
    // If the DOM is available, we can store the new token in the meta tag
    window.document
      .querySelector('meta[name="csrf-token"]')
      ?.setAttribute('content', newToken);
    return newToken;
  }

  const csrfToken = window.document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute('content');

  if (!csrfToken) {
    throw new Error('Missing CSRF token in doc');
  }

  return csrfToken;
};

export const CRDBRD_CSRF_TOKEN = 'CRDBRD_CSRF_WITH_DATE';
const CRDBRD_CSRF_TOKEN_SEPERATOR = '-';
const TOKEN_TIMEOUT = 1 * 60 * 60 * 1000;

// The native csrf token is stored in session storage, and should under normal situations
//   always be valid. However, when we get the refresh variable set to true, that means that
//   a 422 error has already occurred. So best that we then force a refresh from host.
const getNativeCsrfToken = async (refresh: boolean) => {
  if (!refresh) {
    const storedCsrfToken = fetchStoredNativeCsrfToken();
    if (storedCsrfToken) {
      return storedCsrfToken;
    }
  }

  const csrfToken = await fetchCsrfTokenFromHost();
  storeNativeCsrfToken(csrfToken);
  return csrfToken;
};

const storeNativeCsrfToken = (token: string) => {
  sessionStorage.setItem(
    CRDBRD_CSRF_TOKEN,
    [Date.now(), token].join(CRDBRD_CSRF_TOKEN_SEPERATOR),
  );
};

export const clearNativeCsrfToken = () => {
  sessionStorage.removeItem(CRDBRD_CSRF_TOKEN);
};

const fetchStoredNativeCsrfToken = () => {
  const localCsrfData = sessionStorage.getItem(CRDBRD_CSRF_TOKEN);
  if (localCsrfData) {
    const { time, csrfToken } = parseNativeCsrfToken(localCsrfData);
    if (time + TOKEN_TIMEOUT > Date.now()) {
      return csrfToken;
    }
  }
};

const parseNativeCsrfToken = (token: string) => {
  const [t, ...rest] = token.split(CRDBRD_CSRF_TOKEN_SEPERATOR);
  return {
    time: Number(t),
    csrfToken: rest.join(CRDBRD_CSRF_TOKEN_SEPERATOR),
  };
};

const fetchCsrfTokenFromHost = async () => {
  // By asserting that the data is unknown,
  // we force ourselves to validate that the data is what we expect.
  let data: unknown;
  let res: Response | HttpResponse;
  try {
    if (Capacitor.getPlatform() === 'android') {
      const options = {
        url: `https://${window.location.hostname}/csrf-info`,
        headers: { Accept: 'application/json' },
      };

      res = await CapacitorHttp.get(options);

      data = res.data;
    } else {
      res = await fetch(`https://${window.location.hostname}/csrf-info`, {
        credentials: 'include',
      });
      data = await res.json();
    }
  } catch (err) {
    const s = Sentry.getCurrentScope();
    s.setTag('fetch_csrf_error', 'failed_fetch');
    s.setTag('fetch_csrf_error_status', 'none');
    throw err;
  }

  // At this point the fetch did not error, but we can still have invalid responses
  // Instead of trying to parse all possible issues, we just assert if the output contains
  // what we need. This also doubles as a type insurance for typescript.
  try {
    return csrfResponseValidator(data, 'csrfResponse').token;
  } catch (err) {
    const s = Sentry.getCurrentScope();
    s.setTag('fetch_csrf_error', 'failed_parse');
    s.setTag('fetch_csrf_error_status', String(res.status));
    throw new Error('Invalid CSRF token in request.');
  }
};

const csrfResponseValidator = object(
  {
    token: string(),
  },
  { ignoreUnknown: true },
);
