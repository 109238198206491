import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useDisclosure, useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import * as Sentry from '@sentry/react';
import { httpSwitchAuthRequest } from 'screens/Authentication/SignInFromSwitch';
import { globalizeAuthUrl } from 'screens/Authentication/SignInWithTokenAccountSelect';
import { AlertDialog } from 'components/dialogs/AlertDialog';
import React, { useCallback, useEffect, useState } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';
import { decodeGraphqlId } from 'utils/decodeGraphqlId';
import { authenticatedHttpRequest } from 'utils/http';
import { CANONICAL_PREFIXES, CHAT_THREAD_PATH } from 'utils/routes';
import { useSession } from 'utils/sessionProvider';

declare global {
  interface Window {
    handleCanonicalLink: (link: string, tenantShortCode?: string) => void;
  }
}

type NotificationHandlerResponse =
  | {
      action: 'SWITCH_ACCOUNT';
      pathname: string;
      switchUrl: any;
      name: any;
    }
  | {
      action: 'NAVIGATE';
      pathname: string;
    }
  | {
      action: 'INVALID_ACCOUNT' | 'ERROR';
    };

const NotificationHandler = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { handleCanonicalLink: handleCanonicalLinkFromHook } =
    useHandleCanonicalLink();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [tenantName, setTenantName] = useState<string>();
  const [switchUrl, setSwitchUrl] = useState<string>();
  const [resourcePath, setResourcePath] = useState<string>();
  const { resetAppState, isAuthenticated, tenant } = useSession();

  const onFailSwitch = useCallback(() => {
    toast({
      title: t`Could not switch account`,
      description: 'There was an error while switching account.',
      status: 'error',
      isClosable: true,
    });
    onClose();
  }, [toast, onClose]);

  const handleCanonicalLink = useCallback(
    async (link: string, tenantShortCode?: string) => {
      const result = await handleCanonicalLinkFromHook(link, tenantShortCode);

      if (result.action === 'NAVIGATE' && result.pathname) {
        navigate(result.pathname);
      } else if (
        result.action === 'SWITCH_ACCOUNT' &&
        result.pathname &&
        result.switchUrl &&
        result.name
      ) {
        setTenantName(result.name);
        setSwitchUrl(result.switchUrl);
        setResourcePath(result.pathname);
        onOpen();
      } else if (result.action === 'INVALID_ACCOUNT') {
        toast({
          title: t`Invalid resource`,
          description: 'Cannot access the resource with the current account',
          status: 'error',
          isClosable: true,
        });
      } else {
        toast({
          title: t`Could not find the desired resource`,
          description: 'Errorcode: PN-SA',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [handleCanonicalLinkFromHook, toast, navigate],
  );

  const switchAccount = useCallback(() => {
    if (!switchUrl || !resourcePath) return;

    authenticatedHttpRequest(switchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then(async (res) => {
        if (res.status == 200) {
          const responseJson = await res.json();
          const switchUrl = globalizeAuthUrl(responseJson.switch_auth_link);
          const token = switchUrl.split('/').pop();
          if (token) {
            httpSwitchAuthRequest(token).then(async (res) => {
              if (res.status == 200) {
                onClose();
                await PushNotifications.removeAllListeners();
                await App.removeAllListeners();
                resetAppState();
                navigate(resourcePath);
              }
            });
          } else {
            onFailSwitch();
          }
        } else {
          onFailSwitch();
        }
      })
      .catch(() => {
        onFailSwitch();
      });
  }, [switchUrl, resourcePath, navigate]);

  useEffect(() => {
    window.handleCanonicalLink = handleCanonicalLink;
  }, [handleCanonicalLink]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const addPushListeners = async () => {
        await PushNotifications.removeAllListeners();
        await PushNotifications.addListener(
          'pushNotificationReceived',
          (notification) => {
            console.log(
              'Push notification received: ',
              JSON.stringify(notification),
            );
          },
        );

        await PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (action) => {
            const canonicalLink =
              action.notification.data.canonicalLink ||
              action.notification.data.custom.canonicalLink ||
              action.notification.data.custom.a?.canonicalLink;
            const tenantShortCode =
              action.notification.data.tenant ||
              action.notification.data.custom.tenant ||
              action.notification.data.custom.a?.tenant;
            console.log(
              'Push notification action performed',
              action.actionId,
              JSON.stringify(action.notification),
              canonicalLink,
              tenantShortCode,
            );
            if (action.actionId === 'tap' && canonicalLink) {
              window.handleCanonicalLink(canonicalLink, tenantShortCode);
            }
          },
        );
      };

      const addAppListeners = async () => {
        await App.removeAllListeners();

        await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
          const url = new URL(event.url);
          if (!isValidAppUrl(url)) {
            return window.open(event.url);
          }

          const path = event.url.split('/').slice(3).join('/');

          if (path?.startsWith('zoom/auth-redirect')) {
            window.__connectZoom?.(event.url);
            return;
          }

          if (path) {
            window.handleCanonicalLink(
              `https://${window.location.hostname}/${path}`,
            );
          }
        });
      };

      addAppListeners();

      if (isAuthenticated) {
        addPushListeners();
      }
    }
  }, [isAuthenticated, tenant]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t`Switch account?`}
      cancelButton={{ title: t`Cancel`, onClick: onClose }}
      actionButton={{
        title: t`Switch`,
        onClick: switchAccount,
      }}
    >
      {t`This resource is available in another account. Would you like to sign in to ${tenantName}`}
    </AlertDialog>
  );
};

export default NotificationHandler;

const NON_APP_DOMAINS = [
  'www.trustedfamily.com',
  'trustedfamily.com',
  'new.trustedfamily.com',
  'support.trustedfamily.com',
  'newsletters.trustedfamily.com',
];

const isValidAppUrl = (url: URL) => {
  if (NON_APP_DOMAINS.includes(url.hostname)) {
    return false;
  }

  return true;
};

const useHandleCanonicalLink = () => {
  const { tenant } = useSession();
  return {
    handleCanonicalLink: (
      link: string,
      tenantShortCode?: string,
    ): Promise<NotificationHandlerResponse> =>
      handleCanonicalLink(link, {
        tenantShortCode: tenant?.shortcode,
        notificationTenantShortCode: tenantShortCode,
      }),
  };
};

interface HandleCanonicalLinkOptions {
  tenantShortCode: string | undefined;
  notificationTenantShortCode: string | undefined;
}

export const handleCanonicalLink = async (
  link: string,
  options: HandleCanonicalLinkOptions,
): Promise<NotificationHandlerResponse> => {
  const url = new URL(link);

  // Make sure to support lvh and trustedfamily.com here
  if (!url.origin.startsWith('https://app.')) {
    throw new Error('Could not find valid origin in link');
  }

  if (!!matchPath(CHAT_THREAD_PATH, url.pathname)) {
    if (options.notificationTenantShortCode === options.tenantShortCode) {
      return {
        action: 'NAVIGATE',
        pathname: url.pathname,
      };
    } else {
      const res = await handleDifferentAccount(
        (account: any) =>
          account?.tenant?.shortcode === options.notificationTenantShortCode,
        url,
      );
      return res;
    }
  }

  if (
    url.pathname.split('/').length > 1 &&
    CANONICAL_PREFIXES.includes(url.pathname.split('/')[1])
  ) {
    const id = url.pathname.split('/')[2]; // pathname should be /X/ID, we only care about the ID
    const idData = safeDecodeGraphqlId(id);

    if (!idData) {
      Sentry.withScope((s) => {
        s.setLevel('warning');
        s.setTag('redirect_error', 'Could not find valid ID in link');
        s.setTag('redirect_url', link);
        Sentry.captureException(new Error('Invalid native app link'));
      });
      return {
        action: 'ERROR',
      };
    }

    if (
      idData &&
      idData.shortcode &&
      idData.shortcode !== options.tenantShortCode
    ) {
      return await handleDifferentAccount(
        (account: any) => account?.tenant?.shortcode === idData.shortcode,
        url,
      );
    } else {
      return {
        action: 'NAVIGATE',
        pathname: url.pathname,
      };
    }
  }

  return {
    action: 'NAVIGATE',
    pathname: url.pathname + url.search,
  };
};

const safeDecodeGraphqlId = (id: string) => {
  try {
    return decodeGraphqlId(id);
  } catch (e) {
    return null;
  }
};

const handleDifferentAccount = async (
  compareAccount: (account: any) => boolean,
  url: URL,
): Promise<NotificationHandlerResponse> => {
  const accRes = await authenticatedHttpRequest(
    '/auth/list-alternate-accounts',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    },
  );

  if (accRes.status === 200) {
    const data = await accRes.json();
    const account = data.accounts.filter(compareAccount);

    if (account.length) {
      return {
        action: 'SWITCH_ACCOUNT',
        pathname: url.pathname,
        switchUrl: account[0].auth_url,
        name: account[0].tenant?.name,
      };
    } else {
      return {
        action: 'INVALID_ACCOUNT',
      };
    }
  } else {
    return {
      action: 'ERROR',
    };
  }
};
