import { Capacitor } from '@capacitor/core';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import {
  Disposable,
  GraphQLSubscriptionConfig,
  GraphQLTaggedNode,
  OperationType,
  requestSubscription as relayRequestSubscription,
} from 'relay-runtime';
import { getEnvironment } from 'utils/sessionProvider/relayProvider';
import { useLazyLoadQuery } from '.';
import { useLazyLoadQueryWithRef } from './graph';
import { useFlag } from '@unleash/proxy-client-react';

interface RelaySafeContextProps {
  useLazyLoadQuery: typeof useLazyLoadQuery;
  useLazyLoadQueryWithRef: typeof useLazyLoadQueryWithRef;
  requestSubscription: <T extends OperationType>(
    subscription: GraphQLTaggedNode,
    variables: GraphQLSubscriptionConfig<T>['variables'],
    updater?: GraphQLSubscriptionConfig<T>['updater'],
  ) => Disposable;
}

const defaultContext: RelaySafeContextProps = {
  useLazyLoadQuery: () => {
    throw new Error('useLazyLoadQuery not implemented');
  },
  useLazyLoadQueryWithRef: () => {
    throw new Error('useLazyLoadQueryWithRef not implemented');
  },
  requestSubscription: () => {
    throw new Error('requestSubscription not implemented');
  },
};

export const RelaySafeContext =
  React.createContext<RelaySafeContextProps>(defaultContext);

export const useSafeRelay = () => React.useContext(RelaySafeContext);

interface RelaySafeProviderProps {
  children: React.ReactNode;
  ErrorComponent: React.ComponentType<any>;
  LoadingComponent?: React.ComponentType;
}

export const RelaySafeProvider: React.FC<RelaySafeProviderProps> = ({
  children,
  LoadingComponent,
  ErrorComponent,
}) => {
  const isFeatureSEESubscriptionsEnabled = useFlag('sse_graph_subscriptions');
  const isAndroid = Capacitor.getPlatform() === 'android';
  const shouldUseSSE = isFeatureSEESubscriptionsEnabled && !isAndroid;
  const location = useLocation();
  const resetErrors = useRef<() => void | undefined>();
  const hasErrors = useRef(false);

  const requestSubscription = <T extends OperationType>(
    subscription: GraphQLTaggedNode,
    variables: GraphQLSubscriptionConfig<T>['variables'],
    updater?: GraphQLSubscriptionConfig<T>['updater'],
  ) => {
    const env = getEnvironment({
      name: window.location.hostname,
      tenantDomain: window.location.hostname,
      subscriptionsProtocol: shouldUseSSE ? 'sse' : 'ws',
    });

    return relayRequestSubscription<T>(env, {
      subscription,
      variables,
      updater,
    });
  };

  useEffect(() => {
    if (resetErrors.current && hasErrors.current) {
      resetErrors.current();
      hasErrors.current = false;
    }
  }, [location, resetErrors, hasErrors]);

  let Provider = (
    <ErrorBoundary
      fallback={(props) => {
        resetErrors.current = props.resetError;
        hasErrors.current = !!props.error;

        return <ErrorComponent {...props} />;
      }}
    >
      <RelaySafeContext.Provider
        value={{
          useLazyLoadQuery,
          useLazyLoadQueryWithRef,
          requestSubscription,
        }}
      >
        {children}
      </RelaySafeContext.Provider>
    </ErrorBoundary>
  );

  if (LoadingComponent) {
    Provider = <Suspense fallback={<LoadingComponent />}>{Provider}</Suspense>;
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'RelaySafeProvider: No LoadingComponent provided. This may cause issues with the app.',
    );
  }

  return Provider;
};
