import React from 'react';
import {
  ErrorBoundary as DefaultErrorBoundary,
  ErrorBoundaryProps,
} from '@sentry/react';
import { forwardRef } from '@cardboard-ui/react';

export type handleErrorFn = (
  error: unknown,
  componentStack: string | undefined,
  eventId: string,
) => void;

export const ErrorBoundary = forwardRef<ErrorBoundaryProps, 'div'>(
  ({ onError, ...props }, ref) => {
    const handleError: handleErrorFn = (error, componentStack, eventId) => {
      if (onError) {
        onError(error, componentStack, eventId);
      }
      console.error(error, { componentStack, eventId });
    };
    return <DefaultErrorBoundary ref={ref} {...props} onError={handleError} />;
  },
);
