import { useWindowSize } from 'hooks/useWindowSize';
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { Layout, LayoutContext } from './context';

export { Layout };

// This is equivalent to the `md` breakpoint in the chakra-ui theme
const MAX_MOBILE_WIDTH = 768;

export const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowSize();

  const [activeLayout, setActiveLayout] = useState<Layout>(Layout.DESKTOP);

  useLayoutEffect(() => {
    if (width && width <= MAX_MOBILE_WIDTH) {
      setActiveLayout(Layout.MOBILE);
    } else {
      setActiveLayout(Layout.DESKTOP);
    }
  }, [width]);

  return (
    <LayoutContext.Provider value={{ activeLayout }} children={children} />
  );
};

export const useActiveLayout = () => useContext(LayoutContext).activeLayout;
