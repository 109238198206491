/* eslint-disable no-restricted-imports */

import React, { FC } from 'react';
import {
  Link as ReactRouterLink,
  To as ReactRouterTo,
  useNavigate,
} from 'react-router-dom';
import {
  forwardRef as chakraForwardRef,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  useToast,
} from '@chakra-ui/react';
import { useOpenItemViewer } from 'apps/TenantApp/screens/ItemViewer/useOpenItemViewer';
import { CHAT_PATH } from 'utils/routes';
import { Layout, useActiveLayout } from 'utils/LayoutProvider';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { authenticatedHttpRequest } from 'utils/http';
import { t } from '@lingui/macro';
import { Downloader } from 'plugins/downloader';
import isDevMode from 'utils/isDevMode';
import { Share } from '@capacitor/share';
import * as Sentry from '@sentry/react';

interface InternalLinkProps {
  to: ReactRouterTo;
  toExternal?: never;
  toDownload?: never;
  toMail?: never;
  toTelephone?: never;
  toItemViewer?: never;
  toChat?: never;
  useQuickNavigationHack?: boolean;
}

interface ExternalLinkProps {
  to?: never;
  toExternal: string;
  toDownload?: never;
  toMail?: never;
  toTelephone?: never;
  toItemViewer?: never;
  toChat?: never;
  useQuickNavigationHack?: never;
}

interface DownloadLinkProps {
  to?: never;
  toExternal?: never;
  toDownload: string;
  toMail?: never;
  toTelephone?: never;
  toItemViewer?: never;
  toChat?: never;
  useQuickNavigationHack?: never;
}

interface MailtoLinkProps {
  to?: never;
  toExternal?: never;
  toDownload?: never;
  toMail: string;
  toTelephone?: never;
  toItemViewer?: never;
  toChat?: never;
  useQuickNavigationHack?: never;
}

interface TelephoneLinkProps {
  to?: never;
  toExternal?: never;
  toDownload?: never;
  toMail?: never;
  toTelephone: string;
  toItemViewer?: never;
  toChat?: never;
  useQuickNavigationHack?: never;
}

interface ItemViewerLinkProps {
  to?: never;
  toExternal?: never;
  toDownload?: never;
  toMail?: never;
  toTelephone?: never;
  /** Pass in the ID of the item to be opened in the viewer. */
  toItemViewer: string;
  toChat?: never;
  useQuickNavigationHack?: never;
}

interface ChatLinkProps {
  to?: never;
  toExternal?: never;
  toDownload?: never;
  toMail?: never;
  toTelephone?: never;
  toItemViewer?: never;
  toChat: string;
  useQuickNavigationHack?: never;
}

export type MaybeLinkProps<T> = T & {
  to?: ReactRouterTo;
  toExternal?: string;
  toDownload?: string;
  toMail?: string;
  toTelephone?: string;
  toItemViewer?: string;
  toChat?: string;
};

export type BaseLinkProps =
  | InternalLinkProps
  | ExternalLinkProps
  | DownloadLinkProps
  | MailtoLinkProps
  | TelephoneLinkProps
  | ItemViewerLinkProps
  | ChatLinkProps;

type ReducedChakraLinkProps = Omit<
  ChakraLinkProps,
  'href' | 'isExternal' | 'as' | 'target' | 'download'
>;

interface HoverProps {
  underlineOnHover?: boolean;
}
export type LinkProps = BaseLinkProps & ReducedChakraLinkProps & HoverProps;

/*
  Utility function to check if an object can be used for the Link component.
  Recommended use in combination with MaybeLinkProps type
*/
export function isBaseLinkProps<T>(
  maybeLink: MaybeLinkProps<T>,
): maybeLink is BaseLinkProps & T {
  if (!maybeLink) return false;

  return !!(
    maybeLink.to ||
    maybeLink.toExternal ||
    maybeLink.toDownload ||
    maybeLink.toMail ||
    maybeLink.toTelephone ||
    maybeLink.toItemViewer ||
    maybeLink.toChat
  );
}

const DefaultLinkStyle = {
  _hover: { textDecor: 'none' },
};
export const Link: FC<LinkProps> = chakraForwardRef<LinkProps, 'a'>(
  (linkProps, ref) => {
    const {
      to,
      toExternal,
      toDownload,
      toMail,
      toTelephone,
      toItemViewer,
      toChat,
      underlineOnHover,
      useQuickNavigationHack,
      ...props
    } = linkProps;

    // When links are used in LinkOverlay, there is a possibility of receiving
    //   an explicit `undefined` value as target. This would break external links.
    // This loop prevents that.
    // While we are at it, we also kill other attributes that may cause the same issue
    (Object.keys(props) as (keyof typeof props)[]).forEach((key) => {
      if (props[key] === undefined) {
        delete props[key];
      }
    });
    const linkStyle = underlineOnHover ? {} : DefaultLinkStyle;

    if (to && useQuickNavigationHack && Capacitor.getPlatform() === 'ios') {
      return (
        <NativeTouchChakraLink
          ref={ref}
          as={ReactRouterLink}
          {...linkProps}
          {...linkStyle}
        />
      );
    }
    if (toDownload && Capacitor.isNativePlatform()) {
      return (
        <NativeDownloadChakraLink ref={ref} {...linkProps} {...linkStyle} />
      );
    }

    if (to) {
      return (
        <ChakraLink
          ref={ref}
          to={to}
          as={ReactRouterLink}
          {...linkStyle}
          {...props}
        />
      );
    } else if (toDownload) {
      return (
        <ChakraLink
          ref={ref}
          href={toDownload}
          download
          {...linkStyle}
          {...props}
        />
      );
    } else if (toMail) {
      return (
        <ChakraLink
          ref={ref}
          isExternal
          href={'mailto:' + toMail}
          {...linkStyle}
          {...props}
        />
      );
    } else if (toTelephone) {
      return (
        <ChakraLink
          ref={ref}
          isExternal
          href={'tel:' + toTelephone}
          {...linkStyle}
          {...props}
        />
      );
    } else if (toItemViewer) {
      return <ItemViewerLink {...linkProps} />;
    } else if (toChat) {
      return <ChatLink {...linkProps} />;
    } else {
      // These domains are sharing links, which should behave as internal
      // This means we won't open them in a new tab
      const isExternal = isDevMode()
        ? !toExternal?.includes('app.lvh.me')
        : !toExternal?.includes('app.trustedfamily.com');

      return (
        <ChakraLink
          ref={ref}
          href={toExternal}
          isExternal={isExternal}
          {...linkStyle}
          {...props}
        />
      );
    }
  },
);

const NativeTouchChakraLink = chakraForwardRef<
  InternalLinkProps & ReducedChakraLinkProps & HoverProps,
  'a'
>(({ to, ...props }, ref) => {
  const navigate = useNavigate();
  return <ChakraLink ref={ref} {...props} onTouchEnd={() => navigate(to)} />;
});

const NativeDownloadChakraLink = chakraForwardRef<
  DownloadLinkProps & ReducedChakraLinkProps & HoverProps,
  'a'
>(({ toDownload, onClick, ...props }, ref) => {
  const toast = useToast();
  return (
    <ChakraLink
      ref={ref}
      {...props}
      onClick={async (e) => {
        await downloadFile(toDownload, toast);
        onClick && onClick(e);
      }}
    />
  );
});

const ItemViewerLink: FC<ItemViewerLinkProps> = (props) => {
  const { toItemViewer: itemId, ...otherProps } = props;
  const { openItemTo } = useOpenItemViewer();

  return <Link to={openItemTo(itemId)} {...otherProps} />;
};

const ChatLink: FC<ChatLinkProps> = (props) => {
  const { toChat: chatPath, ...otherProps } = props;
  const isDesktop = useActiveLayout() === Layout.DESKTOP;

  return (
    <Link
      to={`${CHAT_PATH}${chatPath}`}
      onClick={(event) => {
        const isKeyPlusClick =
          event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

        // If key + click, use actual link behaviour, allow to open on chat in new window
        if (isKeyPlusClick) {
          return;
        }

        // Else: We want the app to handle the logic
        if (isDesktop) {
          // If desktop, we prevent the normal URL working
          event.preventDefault();
        }
        // And tell the chat app to open
        window.__openChatPath && window.__openChatPath(chatPath);
        // in mobile mode we will just allow the URLS to do their job
      }}
      {...otherProps}
    />
  );
};

async function downloadFile(
  toDownload: string,
  toast: ReturnType<typeof useToast>,
) {
  let permissions = await Filesystem.requestPermissions();
  if (permissions.publicStorage === 'granted') {
    const res = await authenticatedHttpRequest(`${toDownload}.json`, {});

    if (res.status === 200) {
      const { url, file_name: fileName } = await res.json();
      if (typeof url === 'string' && typeof fileName === 'string') {
        if (Capacitor.getPlatform() === 'ios') {
          toast({
            title: t`Downloading...`,
          });
          Downloader.downloadFile({
            url,
            fileName,
          })
            .then((res) => {
              toast({
                title: t`Download complete`,
                status: 'success',
              });
              Share.share({
                title: fileName,
                url: res.path || url,
              });
            })
            .catch(() => {
              toast({
                title: t`Download failed`,
                status: 'error',
              });
            });
        } else if (Capacitor.getPlatform() === 'android') {
          toast({
            title: t`Downloading file ${fileName}`,
          });
          Filesystem.downloadFile({
            directory: Directory.Documents,
            path: fileName,
            url,
          })
            .then(() => {
              toast({
                title: t`Download complete`,
                status: 'success',
              });
            })
            .catch(() => {
              toast({
                title: t`Download failed`,
                status: 'error',
              });
            });
        }
      }
    } else {
      toast({
        title: t`Download failed`,
        status: 'error',
      });

      Sentry.withScope((s) => {
        s.setLevel('warning');
        s.setTag('downloadUrl', `${toDownload}.json`);
        Sentry.captureException(
          new Error('Failed file download. Could not retrieve file info.'),
        );
      });
    }
  }
}
