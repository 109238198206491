import React from 'react';
import {
  Box,
  Container,
  forwardRef,
  HStack,
  StackDivider,
  useColorMode,
  useColorModeValue,
} from '@cardboard-ui/react';
import { Layout, useActiveLayout } from 'utils/LayoutProvider';
import { Screen } from 'layouts/Screen';
import { useCustomTheme } from 'apps/TenantApp/theme/CustomThemeProvider/useCustomTheme';
import { PublicScreenCustomContent } from 'apps/TenantApp/theme/components/PublicScreens';

interface PublicContainerLayoutProps {
  demoCustomContent?: PublicScreenCustomContent;
}

export const PublicContainerLayout = forwardRef<
  PublicContainerLayoutProps,
  'div'
>(({ children }, ref) => {
  const activeLayout = useActiveLayout();
  const isDesktop = activeLayout === Layout.DESKTOP;
  const { activeCustomTheme } = useCustomTheme();
  const { colorMode } = useColorMode();
  const bgColor = activeCustomTheme.publicScreens.background[colorMode].color;
  const bgImage =
    activeCustomTheme.publicScreens.background[colorMode].base64Image;
  const contentBg = useColorModeValue('white', 'gray.700');

  return (
    <Screen
      bgColor={bgColor}
      backgroundImage={bgImage}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <Container
        minH={isDesktop ? undefined : '100%'}
        my={isDesktop ? 10 : 0}
        p={0}
        shadow="md"
        bg={contentBg}
        borderRadius={['none', 'none', 'base']}
        ref={ref}
        maxW="container.sm"
        display="flex"
        flexDirection="column"
      >
        <HStack
          spacing={0}
          divider={<StackDivider />}
          flex={1}
          alignItems="flex-start"
        >
          <Box flex={1} h="100%" p={isDesktop ? 10 : 5}>
            {children}
          </Box>
        </HStack>
      </Container>
    </Screen>
  );
});
