import React, { useEffect, useState } from 'react';
import {
  Button,
  Circle,
  Heading,
  Link,
  Spinner,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { ArrowCircleRightIcon, TenantNotFound } from 'components/icons';
import { ScreenWithSingleSection } from 'layouts/ScreenWithSingleSection';
import { authenticatedHttpRequest } from 'utils/http';
import { Capacitor } from '@capacitor/core';
import { Navigate } from 'react-router-dom';
import { SIGN_IN_PATH } from 'utils/routes';

export const NoTenant = () => {
  const goToSignIn = Capacitor.isNativePlatform();

  if (goToSignIn) {
    return <Navigate to={SIGN_IN_PATH} />;
  } else {
    return <NoTenantOnWeb />;
  }
};

const NoTenantOnWeb = () => {
  const [loading, setLoading] = useState(true);
  const currentUrl = window.location.toString();
  useEffect(() => {
    getUrlInfo(currentUrl)
      .then((urlInfo) => {
        if (urlInfo.tenantUrl && currentUrl !== urlInfo.tenantUrl) {
          window.location.replace(urlInfo.tenantUrl);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return <NoTenantScreen />;
  }
};

export default NoTenant;

const getUrlInfo = async (url: string) => {
  const response = await authenticatedHttpRequest('/graph', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      query: `
        query UrlInfo($url: URI!) {
          urlInfo(url: $url) {
            tenantUrl
          }
        }
      `,
      variables: {
        url,
      },
    }),
  }).then((result) => result.json());

  return (response?.data?.urlInfo || { tenantUrl: null }) as {
    tenantUrl: string | null;
  };
};

const NoTenantScreen = () => (
  <ScreenWithSingleSection>
    <VStack spacing={5}>
      <Circle bg="blue.300" size="90px">
        <TenantNotFound size="3x" color="white" />
      </Circle>
      <VStack>
        <Heading fontSize="xl">{t`Unknown organization`}</Heading>
        <Text maxWidth="sm" textAlign="center">
          {t`We could not find the organization you are looking for.`}
        </Text>
        <Button
          as={Link}
          rightIcon={<ArrowCircleRightIcon />}
          to={SIGN_IN_PATH}
          colorScheme="blue"
        >
          {t`Go to sign in`}
        </Button>
      </VStack>
    </VStack>
  </ScreenWithSingleSection>
);

const Loading = () => (
  <ScreenWithSingleSection>
    <Spinner />
  </ScreenWithSingleSection>
);
