import React from 'react';
import {
  Box,
  chakra,
  Heading,
  Text,
  TokenInput,
  useColorModeValue,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from './components/AuthenticationScreen';
import { t, Trans } from '@lingui/macro';
import { TrustedFamilyLogo } from './assets/Logo';

type SignInWithEmailTokenProps = {
  email: string;
  onCodeValidated: (code: string) => void;
};

export const SignInWithEmailToken = ({
  email,
  onCodeValidated,
}: SignInWithEmailTokenProps) => {
  return (
    <AuthenticationScreen>
      <VStack textAlign="center">
        <TrustedFamilyLogo />
        <Heading fontSize="2xl">{t`Check your email for a code`}</Heading>
        <Box color="gray.500">
          <Text>
            <Trans>
              We've sent a 6-character code to{' '}
              <chakra.span
                fontWeight="bold"
                color={useColorModeValue('gray.600', 'gray.400')}
              >
                {email}
              </chakra.span>
              .
            </Trans>
          </Text>
          <Text>{t`The code expires shortly, so please enter it soon.`}</Text>
        </Box>
        <TokenInput
          otp
          autoFocus
          expectedLength={6}
          onComplete={onCodeValidated}
          my={4}
        />
        <Text color="gray.500">{t`Can't find your code? Check your spam folder!`}</Text>
      </VStack>
    </AuthenticationScreen>
  );
};
