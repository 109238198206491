import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import React, { useCallback } from 'react';
import { TenantPasswordLogin } from '../../TenantPasswordLogin';
import { TenantData } from '../utils/loadTenantData';
import { useAuthLogic } from '../utils/SignInLogic';
import { GoogleButton } from '..';

export const SigningInWithPasswordScreen = ({
  tenant,
}: {
  tenant: TenantData;
}) => {
  const { setScreen, onPasswordSignIn } = useAuthLogic();
  const toast = useToast();

  const onSubmitLogin = useCallback(
    (login: string, password: string) =>
      onPasswordSignIn({ login, password, tenantId: tenant.id }).catch(() => {
        toast({
          title: t`Could not sign in`,
          description: t`Invalid credentials`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }),
    [onPasswordSignIn, toast, tenant.id],
  );

  return (
    <TenantPasswordLogin
      onSubmitLogin={onSubmitLogin}
      onLookingForOtherPlatform={() => {
        setScreen({ screen: 'select-platform' });
      }}
      ExternalAuth={GoogleButton}
      tenant={tenant}
    />
  );
};
