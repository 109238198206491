import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
} from '@cardboard-ui/react';
import { Dict } from '@cardboard-ui/utils';
import React, {
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useI18n } from 'utils/i18n';
import {
  desktopMenuCustomSettingToStyleConfig,
  DesktopMenuThemeKey,
} from '../components/DesktopMenu';
import {
  textLinkCustomSettingToStyleConfig,
  TextLinkThemeKey,
} from '../components/TextLink';
import { CustomThemeLoader } from '../CustomThemeLoader';
import {
  ColorModeWithSystem,
  ComponentStyleDictionary,
  CustomTheme,
  CustomThemeContext,
  DefaultCustomTheme,
} from './context';
import { RelaySafeProvider } from 'utils/graphClient';

type CustomThemeProviderProps = PropsWithChildren<{
  baseTheme: Dict<any>;
}>;

interface PreloadedCustomThemeProviderProps extends CustomThemeProviderProps {
  preloadedCustomTheme: CustomTheme;
  colorMode: ColorModeWithSystem;
  setColorMode: (newColorMode: ColorModeWithSystem) => void;
  hasLegacyTheme: boolean;
}

export const PreloadedCustomThemeProvider: React.FC<
  PreloadedCustomThemeProviderProps
> = ({
  baseTheme,
  children,
  preloadedCustomTheme,
  colorMode,
  setColorMode,
  hasLegacyTheme,
}) => {
  const { direction } = useI18n();

  const [customComponentStyleConfigs, setCustomComponentStyleConfigs] =
    useState<ComponentStyleDictionary>({});

  useLayoutEffect(() => {
    setCustomComponentStyleConfigs({
      [TextLinkThemeKey]: textLinkCustomSettingToStyleConfig(
        preloadedCustomTheme.textLink,
      ),
      [DesktopMenuThemeKey]: desktopMenuCustomSettingToStyleConfig(
        preloadedCustomTheme.desktopMenu,
      ),
    });
  }, [preloadedCustomTheme.textLink, preloadedCustomTheme.desktopMenu]);

  const theme = useMemo(
    () =>
      extendTheme(baseTheme, {
        components: customComponentStyleConfigs,
        direction: direction,
      }),
    [baseTheme, customComponentStyleConfigs, direction],
  );

  const providerState = useMemo(
    () => ({
      activeCustomTheme: preloadedCustomTheme,
      colorMode,
      setColorMode,
      hasLegacyTheme,
    }),
    [preloadedCustomTheme, colorMode, setColorMode, hasLegacyTheme],
  );

  return (
    <CustomThemeContext.Provider value={providerState}>
      <ChakraProvider theme={theme} resetCSS>
        <ColorModeScript />
        {children}
      </ChakraProvider>
    </CustomThemeContext.Provider>
  );
};

export const CustomThemeProvider: React.FC<CustomThemeProviderProps> = (
  props,
) => {
  return (
    <RelaySafeProvider
      ErrorComponent={() => (
        <PreloadedCustomThemeProvider
          {...props}
          preloadedCustomTheme={DefaultCustomTheme}
          colorMode="system"
          setColorMode={() => {}}
          hasLegacyTheme={false}
        />
      )}
    >
      <CustomThemeLoader {...props} />
    </RelaySafeProvider>
  );
};

export const COLOR_MODE_STORAGE_KEY = 'color-mode';
