import { Capacitor } from '@capacitor/core';
import {
  ChakraProvider,
  extendTheme,
  useColorModePreference,
} from '@cardboard-ui/react';
import { useThemeEditing } from 'apps/TenantApp/screens/OrganisationSettings/screens/Themes/components/useThemeEditing';
import { useLocalStorage } from 'hooks/useBrowserStorage';
import React, { useLayoutEffect, useMemo } from 'react';
import { PropsWithChildren } from 'react';
import { useI18n } from 'utils/i18n';
import {
  desktopMenuCustomSettingToStyleConfig,
  DesktopMenuSettingVariant,
  DesktopMenuThemeKey,
} from '../components/DesktopMenu';
import { ColorModeScript } from '../CustomThemeProvider/ColorModeScript';
import {
  ColorModeWithSystem,
  CustomTheme,
  CustomThemeContext,
} from '../CustomThemeProvider/context';
import { COLOR_MODE_STORAGE_KEY } from '../CustomThemeProvider/provider';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';

export const CUSTOM_THEME_MENU_BG_VARIABLE = 'menu-bgColor';
export const CUSTOM_THEME_MENU_TEXT_VARIABLE = 'menu-textColor';
export const CUSTOM_THEME_MENU_ACTIVE_BG_VARIABLE = 'menu-active-bgColor';
export const CUSTOM_THEME_MENU_ACTIVE_TEXT_VARIABLE = 'menu-active-textColor';

export const CUSTOM_THEME_MOBILE_MENU_BG_VARIABLE = 'mobile-menu-bgColor';
export const CUSTOM_THEME_MOBILE_MENU_TEXT_VARIABLE = 'mobile-menu-textColor';
export const CUSTOM_THEME_MOBILE_MENU_ACTIVE_BG_VARIABLE =
  'mobile-menu-active-bgColor';
export const CUSTOM_THEME_MOBILE_MENU_ACTIVE_TEXT_VARIABLE =
  'mobile-menu-active-textColor';

export const CUSTOM_THEME_MENU_HEADER_IMAGE_VARIABLE = 'menu-header-image';
export const CUSTOM_THEME_MENU_HEADER_REMOVE_PADDING_VARIABLE =
  'menu-header-remove-padding';
export interface CustomThemeHeaderRemovePaddingVariable {
  removePadding?: boolean;
}

export const ACTIVE_COLOR_THEME_VARIABLE = 'active-color-theme';
export const ACTIVE_MOBILE_COLOR_THEME_VARIABLE = 'active-mobile-color-theme';

export const CUSTOM_THEME_KEY = 'custom';

interface DefaultThemeColorSet {
  bgColor: string;
  textColor: string;
  activeBgColor: string;
  activeTextColor: string;
}

interface DefaultTheme {
  themeName: string;
  light: DefaultThemeColorSet;
  dark: DefaultThemeColorSet;
}

export const DEFAULT_THEMES: DefaultTheme[] = [
  {
    themeName: 'Deep Blue',
    light: {
      bgColor: '#2a4365',
      textColor: '#e2e8f0',
      activeBgColor: '#2c5282',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: '#2d3748',
      textColor: '#e2e8f0',
      activeBgColor: '#4a5568',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Princess Pink',
    light: {
      bgColor: 'pink.600',
      textColor: '#e2e8f0',
      activeBgColor: 'pink.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'pink.800',
      textColor: '#e2e8f0',
      activeBgColor: 'pink.700',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Green Machine',
    light: {
      bgColor: 'green.600',
      textColor: '#e2e8f0',
      activeBgColor: 'green.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'green.800',
      textColor: '#e2e8f0',
      activeBgColor: 'green.700',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Red Ranger',
    light: {
      bgColor: 'red.600',
      textColor: '#e2e8f0',
      activeBgColor: 'red.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'red.800',
      textColor: '#e2e8f0',
      activeBgColor: 'red.700',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Mellow Yellow',
    light: {
      bgColor: 'yellow.600',
      textColor: '#e2e8f0',
      activeBgColor: 'yellow.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'yellow.800',
      textColor: '#e2e8f0',
      activeBgColor: 'yellow.700',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Teal Tacos',
    light: {
      bgColor: 'teal.600',
      textColor: '#e2e8f0',
      activeBgColor: 'teal.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'teal.800',
      textColor: '#e2e8f0',
      activeBgColor: 'teal.700',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Just Gray',
    light: {
      bgColor: 'gray.600',
      textColor: '#e2e8f0',
      activeBgColor: 'gray.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'gray.900',
      textColor: '#e2e8f0',
      activeBgColor: 'gray.700',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Orange Oracle',
    light: {
      bgColor: 'orange.600',
      textColor: '#e2e8f0',
      activeBgColor: 'orange.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'orange.800',
      textColor: '#e2e8f0',
      activeBgColor: 'orange.700',
      activeTextColor: '#e2e8f0',
    },
  },
  {
    themeName: 'Poppy Purple',
    light: {
      bgColor: 'purple.600',
      textColor: '#e2e8f0',
      activeBgColor: 'purple.500',
      activeTextColor: '#e2e8f0',
    },
    dark: {
      bgColor: 'purple.800',
      textColor: '#e2e8f0',
      activeBgColor: 'purple.700',
      activeTextColor: '#e2e8f0',
    },
  },
];

type CustomThemeProviderProps = PropsWithChildren<{
  baseTheme: { [key: string]: any };
}>;

type AdditionalArguments = {
  propertyToRead: keyof DefaultThemeColorSet;
  propertyToWrite: keyof DesktopMenuSettingVariant;
  themeVariable: string;
}[];

const useCollectThemeColors = (
  activeThemeName: string | undefined,
  colorMode: 'dark' | 'light',
  additional: AdditionalArguments,
) => {
  const { getThemeVariable } = useThemeEditing();
  const results = {} as Record<keyof DesktopMenuSettingVariant, string>;

  for (let { propertyToRead, propertyToWrite, themeVariable } of additional) {
    const activeDefaultTheme =
      DEFAULT_THEMES.find((t) => t.themeName == activeThemeName) ||
      DEFAULT_THEMES[0];

    const IS_DEFAULT_THEME = activeThemeName !== CUSTOM_THEME_KEY;
    if (IS_DEFAULT_THEME) {
      results[propertyToWrite] = activeDefaultTheme[colorMode][propertyToRead];
    } else {
      const colorModeProperty =
        colorMode === 'light' ? 'colorLight' : 'colorDark';
      results[propertyToWrite] =
        getThemeVariable(themeVariable)?.[colorModeProperty] ||
        activeDefaultTheme[colorMode][propertyToRead];
    }
  }

  return results;
};

export function useCurrentMobileMenuColorScheme() {
  const { getThemeVariable } = useThemeEditing();

  const activeThemeName = getThemeVariable(
    ACTIVE_MOBILE_COLOR_THEME_VARIABLE,
  )?.rawValue;

  const additionalArguments: AdditionalArguments = [
    {
      propertyToRead: 'bgColor',
      propertyToWrite: 'backgroundColor',
      themeVariable: CUSTOM_THEME_MOBILE_MENU_BG_VARIABLE,
    },
    {
      propertyToRead: 'textColor',
      propertyToWrite: 'textColor',
      themeVariable: CUSTOM_THEME_MOBILE_MENU_TEXT_VARIABLE,
    },
    {
      propertyToRead: 'activeBgColor',
      propertyToWrite: 'highlightColor',
      themeVariable: CUSTOM_THEME_MOBILE_MENU_ACTIVE_BG_VARIABLE,
    },
    {
      propertyToRead: 'activeTextColor',
      propertyToWrite: 'highlightTextColor',
      themeVariable: CUSTOM_THEME_MOBILE_MENU_ACTIVE_TEXT_VARIABLE,
    },
  ];

  return {
    light: useCollectThemeColors(activeThemeName, 'light', additionalArguments),
    dark: useCollectThemeColors(activeThemeName, 'dark', additionalArguments),
  };
}

export function useCurrentMenuColorScheme() {
  const { getThemeVariable } = useThemeEditing();
  const activeThemeName = getThemeVariable(
    ACTIVE_COLOR_THEME_VARIABLE,
  )?.rawValue;

  const additionalArguments: AdditionalArguments = [
    {
      propertyToRead: 'bgColor',
      propertyToWrite: 'backgroundColor',
      themeVariable: CUSTOM_THEME_MENU_BG_VARIABLE,
    },
    {
      propertyToRead: 'textColor',
      propertyToWrite: 'textColor',
      themeVariable: CUSTOM_THEME_MENU_TEXT_VARIABLE,
    },
    {
      propertyToRead: 'activeBgColor',
      propertyToWrite: 'highlightColor',
      themeVariable: CUSTOM_THEME_MENU_ACTIVE_BG_VARIABLE,
    },
    {
      propertyToRead: 'activeTextColor',
      propertyToWrite: 'highlightTextColor',
      themeVariable: CUSTOM_THEME_MENU_ACTIVE_TEXT_VARIABLE,
    },
  ];

  return {
    light: useCollectThemeColors(activeThemeName, 'light', additionalArguments),
    dark: useCollectThemeColors(activeThemeName, 'dark', additionalArguments),
  };
}

export const CustomThemeLoader = ({
  baseTheme,
  children,
}: CustomThemeProviderProps) => {
  const { direction } = useI18n();

  const menuColorScheme = useCurrentMenuColorScheme();

  const theme = useMemo(
    () =>
      extendTheme(baseTheme, {
        components: {
          [DesktopMenuThemeKey]:
            desktopMenuCustomSettingToStyleConfig(menuColorScheme),
        },
        direction: direction,
      }),
    [baseTheme, direction, menuColorScheme],
  );

  const [colorMode, persistColorMode] = useLocalStorage<ColorModeWithSystem>(
    COLOR_MODE_STORAGE_KEY,
    'system',
    'device',
  );
  const systemColorMode = useColorModePreference();

  useLayoutEffect(() => {
    if (Capacitor.isNativePlatform()) {
      switch (colorMode === 'system' ? systemColorMode : colorMode) {
        case 'light':
          StatusBar.setStyle({ style: Style.Light });
          Keyboard.setStyle({ style: KeyboardStyle.Light });
          break;

        case 'dark':
          StatusBar.setStyle({ style: Style.Dark });
          Keyboard.setStyle({ style: KeyboardStyle.Dark });
          break;
      }
    }
  }, [colorMode]);

  const customThemeToSupportLegacyComponents: CustomTheme = useMemo(
    () => ({
      desktopMenu: menuColorScheme,
      textLink: {
        light: {
          textColor: '#2B6CB0',
          highlightTextColor: '#2A4365',
        },
        dark: {
          textColor: '#4299E1',
          highlightTextColor: '#90CDF4',
        },
      },
      publicScreens: {
        background: {
          light: {
            color: '#EDF2F7',
          },
          dark: {
            color: '#1A202C',
          },
        },
        contentStyle: {
          light: {
            backgroundColor: 'gray.600',
            textColor: 'white',
          },
          dark: {
            backgroundColor: 'gray.100',
            textColor: 'black',
          },
        },
      },
    }),
    [menuColorScheme],
  );

  return (
    <CustomThemeContext.Provider
      value={{
        activeCustomTheme: customThemeToSupportLegacyComponents,
        colorMode,
        setColorMode: persistColorMode,
        hasLegacyTheme: false,
      }}
    >
      <ChakraProvider theme={theme} resetCSS>
        <ColorModeScript />
        {children}
      </ChakraProvider>
    </CustomThemeContext.Provider>
  );
};
