/**
 * @generated SignedSource<<f23619bb7dc2e9d7a6fdab085bd8cbd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MemberRole = "OWNER" | "%future added value";
export type SpaceTypePublishState = "DELETED" | "PUBLISHED" | "%future added value";
export type provider_SessionMemberInfo_Query$variables = {
  queryId?: string | null;
};
export type provider_SessionMemberInfo_Query$data = {
  readonly queryId: string;
  readonly sessionInfo: {
    readonly __typename: "SessionInfo";
    readonly id: string;
    readonly languageCode: LanguageCodeString;
    readonly member: {
      readonly avatar: {
        readonly url: URIString;
      } | null;
      readonly email: string;
      readonly id: string;
      readonly languageCode: LanguageCodeString | null;
      readonly name: string;
      readonly notificationId: string | null;
      readonly roles: ReadonlyArray<MemberRole>;
      readonly status: {
        readonly guest: boolean;
      } | null;
      readonly trackingId: string | null;
    } | null;
    readonly myPrivateVault: {
      readonly active: boolean;
    } | null;
    readonly spaces: ReadonlyArray<{
      readonly id: string;
      readonly modules: ReadonlyArray<{
        readonly __typename: string;
      }>;
      readonly order: number;
      readonly publishState: SpaceTypePublishState;
    }>;
    readonly tenant: {
      readonly canUpdate: {
        readonly value: boolean;
      };
      readonly domain: string;
      readonly icon: {
        readonly url: URIString;
      } | null;
      readonly id: string;
      readonly languageCode: LanguageCodeString | null;
      readonly name: string;
      readonly shortcode: string;
    } | null;
    readonly unleashSessionId: string;
  };
};
export type provider_SessionMemberInfo_Query = {
  response: provider_SessionMemberInfo_Query$data;
  variables: provider_SessionMemberInfo_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "queryId"
  }
],
v1 = {
  "alias": "queryId",
  "args": [
    {
      "kind": "Variable",
      "name": "value",
      "variableName": "queryId"
    }
  ],
  "kind": "ScalarField",
  "name": "echo",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "width",
    "value": 750
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortcode",
      "storageKey": null
    },
    {
      "alias": "icon",
      "args": (v5/*: any*/),
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": "image(width:750)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthorizationResult",
      "kind": "LinkedField",
      "name": "canUpdate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "MyPrivateVault",
  "kind": "LinkedField",
  "name": "myPrivateVault",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": "unleashSessionId",
  "args": null,
  "kind": "ScalarField",
  "name": "flagsSessionId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trackingId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notificationId",
  "storageKey": null
},
v14 = {
  "alias": "avatar",
  "args": (v5/*: any*/),
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "image(width:750)"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "guest",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishState",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "provider_SessionMemberInfo_Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionInfo",
        "kind": "LinkedField",
        "name": "sessionInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "member",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v4/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v7/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Space",
            "kind": "LinkedField",
            "name": "spaces",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "provider_SessionMemberInfo_Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionInfo",
        "kind": "LinkedField",
        "name": "sessionInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "member",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v4/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v7/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Space",
            "kind": "LinkedField",
            "name": "spaces",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27ae5ec1cb80d6306ec6b66fc8f949de",
    "id": null,
    "metadata": {},
    "name": "provider_SessionMemberInfo_Query",
    "operationKind": "query",
    "text": "query provider_SessionMemberInfo_Query(\n  $queryId: String\n) {\n  queryId: echo(value: $queryId)\n  sessionInfo {\n    id\n    __typename\n    tenant {\n      id\n      name\n      domain\n      shortcode\n      icon: image(width: 750) {\n        url\n      }\n      canUpdate {\n        value\n      }\n      languageCode\n    }\n    myPrivateVault {\n      active\n    }\n    unleashSessionId: flagsSessionId\n    member {\n      id\n      trackingId\n      email\n      name\n      notificationId\n      avatar: image(width: 750) {\n        url\n      }\n      languageCode\n      roles\n      status {\n        __typename\n        guest\n      }\n    }\n    languageCode\n    spaces {\n      id\n      publishState\n      order\n      modules {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d3430128d17de73af0fa0caca84bb46f";

export default node;
