import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';
import { SuspendWithSpinner } from 'components/SuspendWithSpinner';
import useFetchKey from 'hooks/useFetchKey';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { clearNativeCsrfToken } from 'utils/getCsrfToken';
import { Box, Spinner, useToast } from '@cardboard-ui/react';
// eslint-disable-next-line no-restricted-imports
import { graphql, useLazyLoadQuery } from 'utils/graphClient';
import { authenticatedHttpRequest } from 'utils/http';
import isDevMode from 'utils/isDevMode';
import {
  registerNotifications,
  requestPermission,
  shouldUpdateNotificationRegistration,
} from 'utils/push';
import { SIGN_OUT_PATH } from 'utils/routes';
import {
  provider_SessionMemberInfo_Query,
  provider_SessionMemberInfo_Query$data,
} from './__generated__/provider_SessionMemberInfo_Query.graphql';
import { SessionContext, SessionContextInterface } from './context';
import { InitialPayloadType, RelayProvider } from './relayProvider';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { notEmpty } from 'utils/NotEmptyFilter';
import orderBy from 'lodash/orderBy';
import { object, string } from 'checkeasy';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { useCardboardEnv } from '../useCardboardEnv';
import {
  IConfig as UnleashConfig,
  FlagProvider as UnleashProvider,
} from '@unleash/proxy-client-react';
import { create as createZustandStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import { Version } from 'plugins/version';
import isAppDomain from 'utils/isAppDomain';
import { useSignInScreenState } from 'screens/Authentication/SignIn/utils/useSignInScreenState';

const DEV_TOOLS_AVAILABLE_TENANTS = ['2AzSDnUTqwVnGbcV8rRsHQMm77fB'];

const SESSION_INFO_QUERY = graphql`
  query provider_SessionMemberInfo_Query($queryId: String) {
    queryId: echo(value: $queryId)
    sessionInfo {
      id
      __typename
      tenant {
        id
        name
        domain
        shortcode
        icon: image(width: 750) {
          url
        }
        canUpdate {
          value
        }
        languageCode
      }
      myPrivateVault {
        active
      }
      unleashSessionId: flagsSessionId
      member {
        id
        trackingId
        email
        name
        notificationId
        avatar: image(width: 750) {
          url
        }
        languageCode
        roles
        status {
          guest
        }
      }
      languageCode
      spaces {
        id
        publishState
        order
        modules {
          __typename
        }
      }
    }
  }
`;

const getGraphCurrentMemberIdVerifier = object(
  {
    data: object({
      sessionInfo: object(
        {
          member: object(
            {
              id: string(),
            },
            { ignoreUnknown: true },
          ),
        },
        { ignoreUnknown: true },
      ),
    }),
  },
  { ignoreUnknown: true },
);

const getGraphCurrentMemberId = async () => {
  if (Capacitor.isNativePlatform()) {
    clearNativeCsrfToken();
  }

  const result = await authenticatedHttpRequest('/graph', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      query: `
        query SessionMemberVerification {
          sessionInfo {
            member { id }
          }
        }
      `,
      variables: {},
    }),
  });

  if (result.status === 200) {
    try {
      const memberId = getGraphCurrentMemberIdVerifier(
        (await result.json()) as unknown,
        'graphQL response',
      ).data.sessionInfo.member.id;
      return {
        sessionVerified: true,
        memberId,
      };
    } catch {
      return {
        sessionVerified: true,
        memberId: null,
      };
    }
  } else {
    return {
      sessionVerified: false,
      memberId: null,
    };
  }
};

const VERIFY_ACCOUNT_TOAST_ID = 'verify-account-toast';
const VERIFY_ACCOUNT_TIMEOUT = 5_000;

interface SessionProviderProps {
  children: ReactNode | ReactNode[] | null;
}

const InnerSessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const [needsTwoFactor, resetRequiresTwoFactor] = twoFactorState(
    useShallow((s) => [s.needsTwoFactor, s.resetRequiresTwoFactor]),
  );

  const { UNLEASH_API_TOKEN } = useCardboardEnv();
  const [reloadTriggerValue, updateReloadTriggerValue] = useFetchKey();
  const [isReloading, setIsReloading] = useState(false);
  const [hideFeatureGlow, setHideFeatureGlow] = useState(false);
  const [shouldRegisterNotification, setShouldRegisterNotification] =
    useState(false);
  const [isTestflightApp, setIsTestflightApp] = useState<boolean>();
  const navigate = useNavigate();
  const toast = useToast();
  const [, , resetSignInScreen] = useSignInScreenState();

  const data = useLazyLoadQuery<provider_SessionMemberInfo_Query>(
    SESSION_INFO_QUERY,
    {
      queryId: `${reloadTriggerValue}`,
    },
    {
      fetchKey: reloadTriggerValue,
      fetchPolicy: 'store-and-network',
    },
  );

  const currentMemberId = data.sessionInfo.member?.id || null;
  const notificationId = data.sessionInfo.member?.notificationId || null;

  const authenticate = useCallback(() => {
    startTransition(() => {
      resetSignInScreen();
      setIsReloading(true);
      updateReloadTriggerValue();
      setShouldRegisterNotification(true);
    });
  }, []);

  const showVerifyAccountToast = useCallback(() => {
    if (!toast.isActive(VERIFY_ACCOUNT_TOAST_ID)) {
      toast({
        id: VERIFY_ACCOUNT_TOAST_ID,
        title: <Box>{t`Verifying your secure session...`}</Box>,
        status: 'info',
        duration: null,
        isClosable: false,
        icon: <FontAwesomeIcon icon={regular('shield')} fontSize={24} />,
      });
    }
  }, [toast]);

  const closeVerifyAccountToast = useCallback(() => {
    toast.close(VERIFY_ACCOUNT_TOAST_ID);
  }, []);

  const verifyAccount = useCallback(() => {
    getGraphCurrentMemberId()
      .then(({ sessionVerified, memberId: graphCurrentMemberId }) => {
        if (!sessionVerified) {
          showVerifyAccountToast();
          setTimeout(verifyAccount, VERIFY_ACCOUNT_TIMEOUT);
          return;
        }
        closeVerifyAccountToast();
        if (graphCurrentMemberId !== currentMemberId) {
          if (graphCurrentMemberId === null) {
            navigate(SIGN_OUT_PATH); // We signed out, so lets go there
          } else {
            window.resetAppState();
            setShouldRegisterNotification(true);
          }
        }
      })
      .catch(() => {
        showVerifyAccountToast();
        setTimeout(verifyAccount, VERIFY_ACCOUNT_TIMEOUT);
      });
  }, [currentMemberId, navigate]);

  const isTenantUrl = useCallback(
    (url: string) => {
      if (!data.sessionInfo.tenant) {
        throw new Error('Tenant is not set');
      }

      try {
        const urlObject = new URL(url);
        return urlObject.hostname === data.sessionInfo.tenant.domain;
      } catch {
        throw new Error('Invalid URL');
      }
    },
    [data.sessionInfo.tenant],
  );

  useEffect(() => {
    if (notificationId === null) return;

    shouldUpdateNotificationRegistration(notificationId).then((status) => {
      if (status) setShouldRegisterNotification(true);
    });
  }, [notificationId]);

  useEffect(() => {
    if (
      shouldRegisterNotification &&
      notificationId &&
      Capacitor.isNativePlatform()
    ) {
      requestPermission().then((hasPermission) => {
        if (hasPermission) {
          registerNotifications();
        }
        setShouldRegisterNotification(false);
      });
    }
  }, [notificationId, shouldRegisterNotification]);

  useEffect(() => {
    if (Capacitor.isNativePlatform() && data.sessionInfo.member?.email) {
      data.sessionInfo.member.email.endsWith('@trustedfamily.net')
        ? CapacitorUpdater.setChannel({ channel: 'staging' })
        : CapacitorUpdater.setChannel({ channel: 'production' });
    }
  }, [data.sessionInfo.member?.email]);

  const reloadOnVisible = useCallback(() => {
    if (document.visibilityState === 'visible') {
      verifyAccount();
    }
  }, [verifyAccount]);

  useEffect(() => {
    if (data.queryId === `${reloadTriggerValue}`) {
      setIsReloading(false);
    }
  }, [data.queryId, reloadTriggerValue]);

  // This effect clears the requirement for two factor once the user is known
  useEffect(() => {
    if (!isReloading && needsTwoFactor && currentMemberId) {
      resetRequiresTwoFactor();
    }
  }, [isReloading, resetRequiresTwoFactor, needsTwoFactor, currentMemberId]);

  useEffect(() => {
    document.addEventListener('visibilitychange', reloadOnVisible);
    return () => {
      document.removeEventListener('visibilitychange', reloadOnVisible);
    };
  }, [reloadOnVisible]);

  const [devToolsVisible, setDevToolsVisible] = useState(false);

  useEffect(() => {
    const trackingId = data.sessionInfo.member?.trackingId || undefined;
    Sentry.setUser({ id: trackingId });
  }, [data.sessionInfo.member?.trackingId]);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      Version.isTestflight().then(({ value }) => {
        setIsTestflightApp(value);
      });
    }
  }, []);

  const devtoolsActive =
    (!!data.sessionInfo.tenant?.id &&
      DEV_TOOLS_AVAILABLE_TENANTS.includes(data.sessionInfo.tenant?.id)) ||
    isDevMode();

  // It is possible that the app is using older preloaded data, where the spaces would be missing.
  // In that case we will assume that the genealogy is not active.
  const spacesWihGenealogy = (data.sessionInfo.spaces || [])
    .filter(notEmpty) // It is possible that spaces are removed from the Relay internal store, this protects us from that
    .filter(({ publishState }) => publishState === 'PUBLISHED')
    .filter((s) =>
      s.modules.find((m) => m.__typename === 'SpaceFamilyTreeModule'),
    );
  const firstSpaceIdWithGenealogy = orderBy(spacesWihGenealogy, [
    (s) => s.order,
    'asc',
  ])[0]?.id;

  const genealogy = firstSpaceIdWithGenealogy
    ? { active: true as const, firstSpaceIdWithGenealogy }
    : { active: false as const };

  const privateVault = { active: !!data.sessionInfo.myPrivateVault?.active };

  const spaceIds = useMemo(() => {
    return data.sessionInfo.spaces
      .filter(notEmpty)
      .filter(({ publishState }) => publishState === 'PUBLISHED')
      .map(({ id }) => id);
  }, [data.sessionInfo.spaces]);

  const sessionStatus = {
    isAuthenticated: !!currentMemberId,
    needsTwoFactor: needsTwoFactor && !isReloading && !currentMemberId,
    isReloading,
    signOutNow: () => navigate(SIGN_OUT_PATH),
    authenticate,
    isCurrentMember: (memberIsh?: { id: string } | null) => {
      return !!(
        memberIsh &&
        data.sessionInfo?.member &&
        data.sessionInfo?.member?.id === memberIsh?.id
      );
    },
    member: data.sessionInfo.member,
    isGuest: !!data.sessionInfo.member?.status?.guest,
    tenant: data.sessionInfo.tenant,
    spaceIds,
    isTenantUrl,
    resetAppState: window.resetAppState,
    devtools: {
      isVisible: devToolsVisible && devtoolsActive,
      isAvailable: devtoolsActive,
      show: () => {
        setDevToolsVisible(true);
      },
      hide: () => {
        setDevToolsVisible(false);
      },
    },
    genealogy,
    privateVault,
  };

  const tenantId = data.sessionInfo.tenant?.id || null;
  const tenantDomain = data.sessionInfo.tenant?.domain || null;
  const isTrustedFamilyEmail =
    data.sessionInfo.member?.email?.endsWith('@trustedfamily.net');

  const rootDomain = window.location.hostname.split('.').slice(1).join('.');

  const unleashConfig = useMemo(
    () =>
      ({
        url: `https://flags.${rootDomain}/api/frontend`,
        clientKey: UNLEASH_API_TOKEN,
        appName: 'cardboard-app',
        context: {
          sessionId: data.sessionInfo.unleashSessionId,
          userId: currentMemberId,
          properties: {
            tenantId,
            tenantDomain,
            isTrustedFamilyEmail: String(isTrustedFamilyEmail || false),
            isNativeApp: String(Capacitor.isNativePlatform()),
            isAppDomain: String(isAppDomain()),
            appPlatform: Capacitor.getPlatform(),
            isTestflightApp: String(isTestflightApp),
          },
        },
      } as UnleashConfig),
    [
      currentMemberId,
      tenantId,
      tenantDomain,
      isTrustedFamilyEmail,
      isTestflightApp,
    ],
  );

  if (Capacitor.getPlatform() === 'ios' && isTestflightApp === undefined) {
    return <Spinner />;
  }

  return (
    <SessionContext.Provider value={sessionStatus}>
      <UnleashProvider config={unleashConfig}>{children}</UnleashProvider>
    </SessionContext.Provider>
  );
};

declare global {
  interface Window {
    resetAppState: () => void;
  }
}

interface TwoFactorState {
  needsTwoFactor: boolean;
  requiresTwoFactor: () => void;
  resetRequiresTwoFactor: () => void;
}

export const twoFactorState = createZustandStore<TwoFactorState>()((set) => ({
  needsTwoFactor: false,
  requiresTwoFactor: () => set({ needsTwoFactor: true }),
  resetRequiresTwoFactor: () => set({ needsTwoFactor: false }),
}));

export const SessionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [relayKey, refreshRelayKey] = useFetchKey();
  const resetAppState = useCallback(() => {
    window.clearPreloadDataFromWindow();
    refreshRelayKey();
  }, [refreshRelayKey]);
  const initialPayload = useMemo(getInitialRelayData, [relayKey]);

  useEffect(() => {
    window.resetAppState = resetAppState;
  }, [resetAppState]);

  return (
    <RelayProvider key={relayKey} initialPayload={initialPayload}>
      <SuspendWithSpinner>
        <InnerSessionProvider key="session">{children}</InnerSessionProvider>
      </SuspendWithSpinner>
    </RelayProvider>
  );
};

declare global {
  interface Window {
    initData?: RecordMap;
    preload_SessionMemberInfo_Query?: provider_SessionMemberInfo_Query$data;
    clearPreloadDataFromWindow: () => void;
  }
}

const getPreloadedDataFromWindow = () => {
  const data = window.preload_SessionMemberInfo_Query;

  if (data && data.sessionInfo) {
    return deepCopyWindowData(data);
  } else {
    return null;
  }
};

window.clearPreloadDataFromWindow = () => {
  window.preload_SessionMemberInfo_Query = undefined;
};

function deepCopyWindowData<P>(data: P) {
  if (!data) return data;
  return JSON.parse(JSON.stringify(data)) as P;
}

const getInitialRelayData: () => InitialPayloadType | undefined = () => {
  const payload = getPreloadedDataFromWindow();

  if (payload) {
    const operationDescriptor = createOperationDescriptor(
      getRequest(SESSION_INFO_QUERY),
      { queryId: '0' },
    );
    return { operationDescriptor, payload };
  } else {
    return undefined;
  }
};

export const useSession = () => useContext(SessionContext);
export const useCardboardDevTools = () => useSession().devtools;

interface TenantSessionContextInterface
  extends Omit<SessionContextInterface, 'tenant'> {
  tenant: NonNullable<SessionContextInterface['tenant']>;
}

export const useTenantSession = () => {
  const session = useSession();

  if (!session.tenant) {
    throw new Error('useTenantSession requires tenant on the session');
  }

  return {
    tenant: session.tenant,
    ...session,
  } as TenantSessionContextInterface;
};

interface AuthenticatedSessionContextInterface
  extends Omit<TenantSessionContextInterface, 'member'> {
  member: NonNullable<TenantSessionContextInterface['member']>;
}

export const useAuthenticatedSession = () => {
  const session = useTenantSession();

  if (!session.member) {
    throw new Error('useAuthenticatedSession requires authenticates session');
  }

  return {
    member: session.member,
    ...session,
  } as AuthenticatedSessionContextInterface;
};
