import React from 'react';
import { CUSTOM_THEME } from 'theme';
import {
  Button,
  ButtonGroup,
  ChakraProvider,
  Circle,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@cardboard-ui/react';
import { ApplicationCrashedIcon } from 'components/icons';
import loadable from '@loadable/component';
import LoadingScreen from '../Loading';
import { LayoutProvider } from 'utils/LayoutProvider';
import { ScreenWithSingleSection } from 'layouts/ScreenWithSingleSection';
import { PossibleError } from 'components/GenericErrorBoundary';
import { createIcon } from 'components/icons/createIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { HomeIcon } from 'components/icons';

const RetryIcon = createIcon(light('rotate'));

const Maintenance = loadable(() => import('screens/AppMaintenance'), {
  fallback: <LoadingScreen />,
});

const NetworkDisconnected = loadable(
  () => import('screens/NetworkDisconnected'),
  {
    fallback: <LoadingScreen />,
  },
);
NetworkDisconnected.preload(); // We preload this screen because when network is gone, loading it then is too late

export const AppCrashed = ({
  error,
  resetError,
}: {
  error: unknown;
  resetError: () => void;
}) => {
  const refresh = () => {
    resetError();
    setTimeout(window.resetAppState, 100);
  };

  const possibleError = error as PossibleError;

  if (possibleError.maintenance || possibleError.response?.status === 503) {
    return <Maintenance />;
  }

  if (possibleError.disconnected) {
    return <NetworkDisconnected refresh={refresh} />;
  }

  const goHome = () => {
    window.__goToHomeScreen();
    setTimeout(resetError, 100);
    setTimeout(window.resetAppState, 200);
  };

  return (
    <ChakraProvider theme={CUSTOM_THEME} resetCSS>
      <LayoutProvider>
        <ScreenWithSingleSection>
          <VStack spacing={5}>
            <Circle bg="red.300" size="90px">
              <ApplicationCrashedIcon size="3x" color="white" />
            </Circle>

            <VStack spacing={5}>
              <VStack spacing={1}>
                <Heading fontSize="xl">Oops, something went wrong</Heading>
                <Text textAlign="center">
                  We apologize for the inconvenience.
                </Text>
              </VStack>

              <VStack>
                <Text>Here's what you can do:</Text>
                <UnorderedList style={{ marginInlineStart: '1.5rem' }}>
                  <ListItem>
                    Refresh Page:{' '}
                    <strong>Click or tap the refresh button.</strong> This often
                    fixes temporary issues.
                  </ListItem>
                  <ListItem>
                    Return Home: If refreshing doesn't help,{' '}
                    <strong>return to the home screen to start over.</strong>
                  </ListItem>
                  <ListItem>
                    Wait & Retry: If that doesn't work,{' '}
                    <strong>please wait a moment before trying again.</strong>
                  </ListItem>
                  <ListItem>
                    <strong>Need Help?</strong> Contact our support team for
                    assistance.
                  </ListItem>
                </UnorderedList>
              </VStack>
            </VStack>

            <ButtonGroup>
              <Button leftIcon={<RetryIcon />} onClick={refresh}>
                Refresh Page
              </Button>
              <Button leftIcon={<HomeIcon />} onClick={goHome}>
                Return to Home
              </Button>
            </ButtonGroup>

            <VStack spacing={0}>
              <Text fontWeight="bold">We're on it!</Text>
              <Text fontSize="sm">
                Your issue has been reported and we're working to fix it as
                quickly as possible.
              </Text>
              <Text fontSize="sm">Thank you for your patience.</Text>
            </VStack>
          </VStack>
        </ScreenWithSingleSection>
      </LayoutProvider>
    </ChakraProvider>
  );
};
