import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  forwardRef,
  Heading,
  HStack,
  Link,
  Spinner,
  Stack,
  Text,
  TokenInput,
  useDisclosure,
  useInterval,
  useTimeout,
  useToast,
  VStack,
} from '@cardboard-ui/react';
import { useTenantSession } from 'utils/sessionProvider';
import { t, Trans } from '@lingui/macro';
import { PublicContainerLayout } from '../../layouts/PublicContainerLayout';
import { Form } from 'components/Form';
import { Input } from 'components/Form/Input';

import { authenticatedHttpRequest } from 'utils/http';
import { CheckCircleIcon } from 'components/icons';
import { SIGN_OUT_PATH } from 'utils/routes';
import { OtpIcon, RecoveryIcon, SmsIcon } from 'components/icons/two_factor';
import { AuthenticationScreenHeading } from './components/AuthenticationScreenHeading';
import { twoFactorState } from 'utils/sessionProvider/provider';
import useEffectOnceWhen from 'hooks/useEffectOnceWhen';

const SMS = 'SMS';
const OTP = 'OTP';
const RECOVERY = 'RECOVERY';
type TwoFactorMode = 'SMS' | 'OTP' | 'RECOVERY' | '%undefined%';

const AuthLinkMap = {
  '/auth/otp-auth': OTP,
  '/auth/sms-request': SMS,
  '/auth/recovery-auth': RECOVERY,
} as { [key: string]: TwoFactorMode | undefined };

export const SignInTwoFactor = forwardRef<{}, 'div'>(({}, ref) => {
  const {
    isAuthenticated: isSessionAuthenticated,
    authenticate,
    tenant,
  } = useTenantSession();
  const toast = useToast();
  const [activeModes, setActiveModes] = useState<TwoFactorMode[]>([]);
  const [activeMode, setActiveMode] = useState<TwoFactorMode | undefined>(
    undefined,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffectOnceWhen(true, () => {
    authenticatedHttpRequest('/auth/two-factor-info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        const authLinks = data.auth_links as string[];
        const modes = authLinks
          .map((s: string) => AuthLinkMap[s] || '%undefined%')
          .filter((s) => s !== '%undefined%');

        setActiveModes(modes);
        setActiveMode(modes[0]);
      } else if (response.status === 403) {
        // Either there is no 2FA or we are already authenticated
        setIsAuthenticated(true);
      } else if (response.status === 401) {
        twoFactorState((s) => s.resetRequiresTwoFactor)();
      } else {
        // Very unlikely to happened, so quality of message is not that important.
        toast({
          title: t`Two Factor setup failed`,
          description: t`There is an issue with finding your two factor configuration. You can try to refresh the page, or sign out.`,
          position: 'top',
          status: 'error',
          duration: null,
          isClosable: true,
        });
      }
    });
  });

  useEffect(() => {
    if (isAuthenticated && !isSessionAuthenticated) {
      authenticate();
    }
  }, [isAuthenticated, isSessionAuthenticated]);

  return (
    <PublicContainerLayout ref={ref}>
      <Stack>
        <AuthenticationScreenHeading>
          <HStack>
            <Heading fontSize="xl" fontWeight="bold">
              {t`Two factor authentication`}
            </Heading>
          </HStack>
        </AuthenticationScreenHeading>
        <Box>
          <Collapse
            in={isAuthenticated || isSessionAuthenticated}
            animateOpacity
          >
            <Stack align="center">
              <CheckCircleIcon size="4x" color="green.400" />
              <Text>{t`That code is valid, opening up the app.`}</Text>
              <Spinner />
            </Stack>
          </Collapse>
          <Collapse in={!isOpen && !isAuthenticated} animateOpacity>
            {activeModes.length === 0 && (
              <Center>
                <Spinner />
              </Center>
            )}
            {activeMode === OTP && !isOpen && (
              <OneTimePasswordForm
                onComplete={() => setIsAuthenticated(true)}
              />
            )}
            {activeMode === SMS && !isOpen && (
              <SMSForm onComplete={() => setIsAuthenticated(true)} />
            )}
            {activeMode === RECOVERY && !isOpen && (
              <RecoveryTokenForm onComplete={() => setIsAuthenticated(true)} />
            )}
            {activeModes.length > 1 && (
              <>
                <Divider py={2} />
                <Text>
                  <Trans>
                    {'Problem using your two factor?'}{' '}
                    <Button variant="link" onClick={onOpen}>
                      {'Try another method'}
                    </Button>
                  </Trans>
                </Text>
              </>
            )}
          </Collapse>
          <Collapse in={isOpen && !isAuthenticated} animateOpacity>
            <ModeSwitch
              onChange={(v) => {
                setActiveMode(v);
                onClose();
              }}
              options={activeModes}
            />
          </Collapse>
        </Box>
        <Box>
          <Button as={Link} to={SIGN_OUT_PATH}>
            {t`Sign out`}
          </Button>
        </Box>
      </Stack>
    </PublicContainerLayout>
  );
});

export default SignInTwoFactor;

interface ModeSwitchProps {
  onChange: (mode: TwoFactorMode) => void;
  options: TwoFactorMode[];
}

const ButtonContentMap = (): {
  [key: string]: { Icon: any; text: string };
} => ({
  [SMS]: {
    Icon: SmsIcon,
    text: t`Receive code via SMS`,
  },
  [OTP]: {
    Icon: OtpIcon,
    text: t`Use your app to generate a code`,
  },
  [RECOVERY]: {
    Icon: RecoveryIcon,
    text: t`Use one of your recovery tokens`,
  },
});

const ModeSwitch = ({ onChange, options }: ModeSwitchProps) => {
  return (
    <>
      <VStack align="start">
        <Text>{t`Choose one of these options`}</Text>
        {options
          .map((mode) => ({ mode, ...ButtonContentMap()[mode] }))
          .map(({ mode, Icon, text }) => (
            <Button
              key={mode}
              justifyContent="start"
              leftIcon={<Icon size="lg" />}
              onClick={() => onChange(mode)}
              w="100%"
            >
              {text}
            </Button>
          ))}
      </VStack>
    </>
  );
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const ensureMinimalTime: <T>(
  ms: number,
  promise: Promise<T>,
) => Promise<T> = async (ms, promise) => {
  await sleep(ms);
  return await promise;
};

export const DelayedRender: FC<PropsWithChildren<{ delay: number }>> = ({
  delay,
  children,
}) => {
  const [render, shouldRender] = useState(false);

  useEffect(() => {
    const i = setTimeout(() => shouldRender(true), delay);
    return () => clearTimeout(i);
  });

  return render ? <>{children}</> : <></>;
};

interface FormFields {
  token: string;
}

const OneTimePasswordForm = ({ onComplete }: { onComplete: () => void }) => {
  const { handleSubmit, register, setFocus, formState } = useForm<FormFields>();
  const toast = useToast();

  useTimeout(() => setFocus('token'), 25);

  const onSubmit = (args: { token: string }) => {
    return authenticatedHttpRequest('/auth/otp-auth', {
      method: 'POST',
      body: JSON.stringify({
        otp: args.token,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then((response) => {
      if (response.status === 200) {
        toast.close(twoFactorAuthFailedToast);
        onComplete();
      } else {
        twoFactorFailedToast(toast);
      }
    });
  };

  return (
    <Form
      CTASubmitName={t`Verify app code`}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={formState.isSubmitting}
    >
      <VStack align="start">
        <Text>{t`Enter the code generated by your app`}</Text>

        <HStack justify="center" width="100%">
          <TokenInput
            otp
            {...register('token')}
            isDisabled={formState.isSubmitting}
            expectedLength={6}
            onComplete={() => handleSubmit(onSubmit)()}
          />
        </HStack>
      </VStack>
    </Form>
  );
};

const SMSResendTimeoutInSeconds = 30;
const smsResendToastId = 'sms-resend';
const twoFactorAuthFailedToast = 'two-factor-confirm-failed';

const SMSForm = ({ onComplete }: { onComplete: () => void }) => {
  const { handleSubmit, register, setFocus, formState } = useForm<FormFields>();
  const [smsSent, setSmsSent] = useState(false);
  const [smsSendTimeout, setSmsSendTimeout] = useState(0); // -1 means the SMS is in flight
  const allowSmsResend = smsSendTimeout === 0;
  const toast = useToast();

  const onSubmit = useCallback((args: FormFields) => {
    return authenticatedHttpRequest('/auth/sms-auth', {
      method: 'POST',
      body: JSON.stringify({
        ['sms-code']: args.token,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then((response) => {
      if (response.status === 200) {
        toast.close(twoFactorAuthFailedToast);
        onComplete();
      } else {
        twoFactorFailedToast(toast);
      }
    });
  }, []);

  const requestSms = useCallback(() => {
    if (!allowSmsResend) {
      return Promise.reject("Can't send SMS yet");
    } else {
      toast.close(smsResendToastId);
      setSmsSent(false);
      setSmsSendTimeout(-1);
      return ensureMinimalTime(
        1000, // Make sure the user sees that we are sending the SMS
        authenticatedHttpRequest('/auth/sms-request', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        }),
      ).then(async (response) => {
        if (response.status === 200) {
          setSmsSent(true);
          setSmsSendTimeout(SMSResendTimeoutInSeconds);
        } else {
          setSmsSendTimeout(0);
          toast({
            id: smsResendToastId,
            title: t`Could not send the SMS token`,
            position: 'top',
            status: 'error',
          });
        }
        setTimeout(() => setFocus('token'), 25);
      });
    }
  }, [allowSmsResend]);

  const resendSms = useCallback(() => {
    if (allowSmsResend) {
      requestSms();
    } else {
      toast({
        id: smsResendToastId,
        title: t`Wait to resend SMS`,
        description: t`SMSes with tokens can only be requested every 30 seconds. Please wait and try again.`,
        position: 'top',
        status: 'warning',
        isClosable: true,
      });
    }
  }, [allowSmsResend]);

  // Create visual countdown for the user
  useInterval(() => {
    setSmsSendTimeout((s) => (s > 0 ? s - 1 : 0));
  }, 1000);

  useEffect(() => {
    if (smsSendTimeout === 0 && smsSent) {
      toast.close(smsResendToastId);
    }
  }, [smsSendTimeout]);

  useEffectOnceWhen(true, requestSms);

  return (
    <>
      <Collapse in={!smsSent} animateOpacity>
        <VStack>
          <Text>{t`We are sending the token via SMS to your phone.`}</Text>
          {smsSendTimeout === -1 && <Spinner />}
        </VStack>
      </Collapse>
      <Collapse in={smsSent} animateOpacity>
        <Form
          CTASubmitName={t`Verify SMS code`}
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={formState.isSubmitting}
        >
          <VStack align="start">
            <Text>{t`Enter the code received by your phone`}</Text>

            <HStack justify="center" width="100%">
              <TokenInput
                otp
                autoFocus={smsSent}
                {...register('token')}
                isDisabled={formState.isSubmitting}
                expectedLength={6}
                onComplete={() => handleSubmit(onSubmit)()}
              />
            </HStack>
          </VStack>
        </Form>
      </Collapse>
      <Box pt={2} hidden={!(smsSent || allowSmsResend)}>
        <Button
          w="100%"
          size="sm"
          onClick={resendSms}
          isDisabled={!allowSmsResend}
        >
          {t`Send SMS again`}
          {smsSendTimeout > 0 && ` (${smsSendTimeout}s)`}
        </Button>
      </Box>
    </>
  );
};

const RecoveryTokenForm = ({ onComplete }: { onComplete: () => void }) => {
  const { handleSubmit, register, formState } = useForm<FormFields>();
  const toast = useToast();

  const onSubmit = (args: FormFields) => {
    return ensureMinimalTime(
      1000,
      authenticatedHttpRequest('/auth/recovery-auth', {
        method: 'POST',
        body: JSON.stringify({
          'recovery-code': args.token,
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }),
    ).then((response) => {
      if (response.status === 200) {
        toast.close(twoFactorAuthFailedToast);
        onComplete();
      } else {
        twoFactorFailedToast(toast);
      }
    });
  };

  return (
    <Form
      CTASubmitName={t`Use Recovery token`}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={formState.isSubmitting}
    >
      <VStack align="start">
        <Text>{t`Enter one of your recovery tokens`}</Text>
        <Input
          autoFocus
          {...register('token')}
          isDisabled={formState.isSubmitting}
        />
      </VStack>
    </Form>
  );
};

const twoFactorFailedToast = (toast: ReturnType<typeof useToast>) => {
  toast({
    id: twoFactorAuthFailedToast,
    title: t`Two Factor verification failed`,
    position: 'top',
    status: 'error',
  });
};
